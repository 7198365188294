/**
* Bootstrap Setup
*/

// Modify Theme Colors
$primary: #00d5e2;
$secondary: #3a0070;

// Modify Container Size
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 720px,
  xl: 720px,
  xxl: 720px
);

// Configuration
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// Layout & Components
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
//@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
//@import '~bootstrap/scss/transitions';
//@import '~bootstrap/scss/dropdown';
//@import '~bootstrap/scss/button-group';
//@import '~bootstrap/scss/nav';
//@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
//@import '~bootstrap/scss/breadcrumb';
//@import '~bootstrap/scss/pagination';
//@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
//@import '~bootstrap/scss/list-group';
//@import '~bootstrap/scss/close';
//@import '~bootstrap/scss/toasts';
//@import '~bootstrap/scss/modal';
//@import '~bootstrap/scss/tooltip';
//@import '~bootstrap/scss/popover';
//@import '~bootstrap/scss/carousel';
//@import '~bootstrap/scss/spinners';

// Helpers
//@import '~bootstrap/scss/helpers';

// Utilities
@import '~bootstrap/scss/utilities/api';

// Custom changes to bootstrap
.btn-primary {
  color: white;
  font-weight: bold;

  &:hover {
    color: white;
  }
}

.progress {
  width: 25%;
  height: 3px;
}
