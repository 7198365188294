@font-face {
  font-family: "Bariol Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Bariol Regular"),
    url("../fonts/bariol_regular-webfont.woff") format("woff");
}

@import "./bootstrap.scss";

body {
  font-family: "Bariol Regular", sans-serif;
}

.background {
  min-height: 100vh;
  padding: 1rem 0 5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &.driver {
    background-image: url("../images/driver/background.jpg");
  }

  &.assistant {
    background-image: url("../images/assistant/background.jpg");
  }

  h2 {
    font-weight: bold;
  }

  .arrow::after {
    content: "";
    width: 147px;
    height: 60px;
    position: absolute;
    right: 0;
    background-image: url("../images/arrow.png");
    margin-top: 24px;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.description {
  h3 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 24px;

    &:not(:first-of-type) {
      margin-top: 24px;
    }
  }

  h6 {
    font-weight: bold;
    margin-bottom: 16px;
  }
}

.document {
  padding: 20px;
  border-radius: 5px;
  border: 2px dashed;

  &.default {
    border-color: #bdbdbd;
  }

  &.uploading {
    border-color: $secondary;

    .filename {
      color: $secondary;
    }
  }

  &.success {
    border-color: $success;

    .filename {
      color: $success;
    }
  }

  &.error {
    border-color: $danger;

    .filename {
      color: $danger;
    }
  }

  p {
    margin-bottom: 8px;
  }

  .file-label {
    overflow: hidden;
    position: relative;

    [type="file"] {
      cursor: pointer;
      min-height: 100%;
      min-width: 100%;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .btn::before {
      content: "\002B";
      margin-right: 5px;
      font-size: 24px;
      line-height: 1;
    }
  }
}

.flex-space-between {
  flex: 1 1 auto;
}

@keyframes submit {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: nowrap;
  margin-bottom: 10px;

  .item {
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: $secondary;
    animation: submit 0.8s ease-in-out alternate infinite;

    &.item-1 {
      animation-delay: -0.4s;
    }

    &.item-2 {
      animation-delay: -0.2s;
    }
  }
}

[type="submit"] {
  width: 100%;
  font-size: 20px;
  line-height: 1;
  position: relative;

  &::after {
    content: "→";
    margin-left: 8px;
    position: absolute;
    top: 10%;
  }
}

.faq {
  :not(:last-child) {
    margin-top: 16px;
  }

  p {
    margin: 0;

    &:first-child {
      font-weight: 600;

      &::before {
        content: "Q:";
        margin-right: 5px;
      }
    }

    &:last-child::before {
      content: "A:";
      margin-right: 5px;
    }
  }

  p:first-child {
    font-weight: 600;

    &::before {
      content: "Q:";
      margin-right: 5px;
    }
  }
}

.MuiStepIcon-root.MuiStepIcon-completed,
.MuiStepIcon-root.MuiStepIcon-active {
  color: $secondary !important;
}

.MuiStepLabel-labelContainer {
  display: none;

  @media (min-width: 576px) {
    display: block;
  }
}

.input-error {
  width: 100%;
  font-size: 80%;
  margin-top: 0.25rem;
  color: $danger;
}

.input-hint {
  width: 100%;
  font-size: 80%;
  margin-top: 0.25rem;
  color: $primary;
}

form:not(.vertical-form) {
  .form-group {
    .d-flex {
      .col {
        margin: 0 8px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .label {
        flex: 0 0 40%;
        margin: 0;
        padding-right: 8px;
      }

      > .form-control,
      > .input-group,
      .form-select,
      button,
      .d-flex,
      .row {
        flex: 0 0 60%;
      }

      .input-error, .input-hint {
        margin-left: 40%;
      }

      .form-check-inline {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      > button,
      .btn-group {
        margin-left: 40%;
      }

      .btn-group {
        display: flex;
        flex: 0 0 60%;

        button {
          width: 100%;
          font-size: 20px;
          line-height: 1;

          &::after {
            content: "";
          }
        }
      }

      .submit {
        margin-left: 67%;
      }
    }
  }
}

label[for="terms"] {
  font-size: 13px;
  color: $gray-600;
}

.form-label {
  font-weight: bold;
  margin-bottom: 0;
}

::placeholder {
  color: $gray-600 !important;
  opacity: 0.5 !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray-600 !important;
  opacity: 0.5 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $gray-600 !important;
  opacity: 0.5 !important;
}

.delete {
  width: 24px;
  height: 24px;
  border: none;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAApUlEQVRIieWUSw7CMAxEH5yGRTgEZQO3Ysn9smHdVipKD1E2RjJJKqjb8qslS9HEmbEycWApcQK6KM+vHFxlsG5kMw+c65FkC4icB2D3IeGb3YOvELgAO+CqsBbYA94qrAeqEMwBQdIJVkS1JgFN6KJ1mELgLrJV+xugydQl8bFJfusVzW6yFyLdbRDMPxP436+iNnBVQ4qPQEn6zvuyBA6Gpn4gbnBuUo4LDO5zAAAAAElFTkSuQmCC");

  &:focus {
    outline: none;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(45deg);
  }

  50% {
    opacity: 1;
    transform: rotate(150deg);
  }

  62% {
    opacity: 0;
  }

  65% {
    opacity: 0;
    transform: rotate(200deg);
  }

  90% {
    transform: rotate(340deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  .item {
    width: 100%;
    height: 100%;
    position: absolute;
    animation: loading 2s linear infinite;

    &.item-1 {
      animation-delay: 0.2s;
    }

    &.item-2 {
      animation-delay: 0.4s;
    }

    &.item-3 {
      animation-delay: 0.6s;
    }

    &.item-4 {
      animation-delay: 0.8s;
    }

    span {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 50%;
      background-color: $primary;
      position: absolute;
      left: 50%;
      margin-top: -10px;
      margin-left: -10px;
    }
  }
}

.summary {
  margin-bottom: 20px;

  h5 {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  h6 {
    margin: 0;
    font-weight: bold;
  }

  div {
    padding: 8px;
    display: flex;

    &:nth-child(even) {
      background-color: $primary;
    }

    span {
      flex: 0 0 50%;

      &:last-child {
        &::before {
          content: ":";
          margin-right: 16px;
        }
      }
    }
  }
}

.payment-form {
  input {
    width: 100%;
    display: block;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.25rem 0.75rem;
    color: #212529;
  }
}

.StripeElement {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  color: #212529;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.StripeElement--invalid {
  border: 1px solid $danger;
}

.help {
  color: white;
  background-color: $secondary;
  padding: 0 5px;
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer;
}

p.label, div.label {
  font-weight: bold;
}

iframe {
  width: 100%;

  &[title="driver-enquiry"] {
    height: 3070px;

    @media (min-width: 380px) {
      height: 3000px;
    }

    @media (min-width: 768px) {
      height: 2600px;
    }
  }

  &[title="pa-enquiry"] {
    height: 3250px;

    @media (min-width: 430px) {
      height: 2800px;
    }

    @media (min-width: 768px) {
      height: 2500px;
    }
  }
}

.dialog {
  &.closed {
    display: none;
  }

  &.open {
    display: block;
  }

  .dialog-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
}
